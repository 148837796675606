<template>
  <div class="card-content">
    <div class="main">
      <div class="title">
        <div class="item">Document Name</div>
        <div class="item">Document Type</div>
      </div>
      <div class="load-data" v-if="loading">
        <div class="pic"><img src="@/assets/loading.gif" /></div>
      </div>
      <div class="content" v-else-if="list.length > 0">
        <div class="item" v-for="item in list" :key="item.id">
          <div
            class=" name search-button document-name"
            @click="viewDocument(item)"
          >
            {{ item.name }}
          </div>
          <div class=" type">{{ (item.extra && item.extra.type) || "--" }}</div>
        </div>
      </div>
      <div class="content" v-else>
        <div class="no-data">
          <div class="pic"><img src="@/assets/no-data.png" /></div>
          <div class="desc">No Data</div>
        </div>
      </div>
    </div>
    <div class="page" v-if="list.length > 0">
      <Page
        transfer
        :page-size="limit"
        :page-size-opts="[10, 15, 20]"
        :total="total"
        show-sizer
        show-elevator
        show-total
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryCompanyDocument } = api;
export default {
  computed: {},
  props: ["id"],

  components: {},
  data() {
    return {
      list: [],
      page: 1,
      limit: 15,
      filter: {},
      loading: true
    };
  },
  created() {
    const id = this.id;
    _queryCompanyDocument({
      id: id,
      page: this.page,
      limit: this.limit,
      ...this.filter
    })
      .then(response => {
        const { data } = response;
        this.list = data.data;
        this.total = data.total;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {},
  methods: {
    viewDocument(item) {
      window.open(item.url);
    },
    pageChange(page) {
      this.page = page;
      this.queryDocumentList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryDocumentList();
    },
    handleSearch() {
      this.page = 1;
      this.queryDocumentList();
    },
    queryDocumentList() {
      _queryCompanyDocument({
        id: this.id,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
}
@media (max-width: 1400px) and (min-width: 1200px) {
}
@media (max-width: 1200px) {
}
.card-content {
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 0 auto;
  padding-bottom: 10px;
  .main {
    padding-top: 10px;
    .title {
      display: flex;
      height: 40px;
      .item {
        text-align: left;
        padding-top: 8px;
        font-size: 16px;

        font-weight: 500;
        color: #828282;
        width: 50%;
      }
    }
    .content {
      .item {
        display: flex;
        div {
          text-align: left;
          width: 50%;
          font-size: 16px;

          font-weight: 600;
          color: #333333;
          line-height: 18px;
          height: 47px;
          border-bottom: 0.5px solid #e0e0e0;
          padding-top: 13px;
        }
      }
      .no-data {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
  .page {
    text-align: right;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
.document-name {
  color: #ef842d !important;
}
.load-data {
  margin-top: 30px;
  text-align: center;
  .pic {
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
