<template>
  <gm-skeleton :showSpin="showSkeleton">
  <div class="homePage">
    <div class="sc-width">
      <div class="bottom-content d-flex d-flex-space-between">
        <div class="left">
          <div class="logo">
            <img :src="company.avatar.url" v-if="!showSkeleton"/>
            <div class="img" v-else></div>
          </div>

          <div class="left-part">
            <div class="left-part-item" @click="choose(0)" :class="{'left-part-item-active': selected == 0 }">
              <div class="left-part-item-word">
                Overview
              </div>
              <div class="left-part-item-icon" >
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
            <div class="left-part-item" @click="choose(1)" :class="{'left-part-item-active': selected == 1 }">
              <div class="left-part-item-word">
                Media Gallery
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
            <div class="left-part-item" @click="choose(2)">
              <div class="left-part-item-word">
                HighLights
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
            <div class="left-part-item" @click="choose(3)" :class="{'left-part-item-active': selected == 3 }">
              <div class="left-part-item-word">
                Products (10)
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
            <div class="left-part-item" @click="choose(4)" :class="{'left-part-item-active': selected == 4 }">
              <div class="left-part-item-word">
                Documents (13)
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
            <div class="left-part-item" @click="choose(5)" :class="{'left-part-item-active': selected == 5 }">
              <div class="left-part-item-word">
                Factories (5)
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
            <div class="left-part-item" @click="choose(6)" :class="{'left-part-item-active': selected == 6 }">
              <div class="left-part-item-word">
                Members (3)
              </div>
              <div class="left-part-item-icon">
                <img src="@/assets/product/next.svg" alt="" v-if="!showSkeleton"/>
              </div>
            </div>
          </div>
        </div>
        <div id="company-info-0"  class="me-v"></div>
        <div class="mid" @scroll="onMidScroll">
          <div class="top-tips d-flex d-flex-space-between">
            <div class="tip-word">Sign in to create your own storefront now!</div>
            <div class="tip-btn">Log In</div>
          </div>
          <div class="product d-flex">
            <div class="product-name">{{ company.company_name }}</div>
            <div class="product-icons d-flex">
              <div class="product-icons-item"></div>
              <div class="product-icons-item"></div>
              <div class="product-icons-item"></div>
            </div>
          </div>
          <div class="product-desc">
           {{company.company_description}}</div>
          <div class="product-basic">
            <div class="product-basic-part">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Type: </div>
                <div class="product-basic-item-value">{{ company.business_type }}</div>
              </div>
              <div class="product-basic-item">
                <div class="product-basic-item-label">No.of Employees: </div>
                <div class="product-basic-item-value cursor-pointer">{{ company.company_size }}</div>
              </div>
            </div>
            <div class="product-basic-part">
              <div class="product-basic-item">
                <div class="product-basic-item-label">View Count: </div>
                <div class="product-basic-item-value">{{ company.view_history_count }}</div>
              </div>
              <div class="product-basic-item">
                <div class="product-basic-item-label">Year Established: </div>
                <div class="product-basic-item-value cursor-pointer">{{ company.established_year }}</div>
              </div>
            </div>
            <div class="product-basic-part">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Location: </div>
                <div class="product-basic-item-value">{{ company.company_country_info.name }}</div>
              </div>
              <div class="product-basic-item">
                <div class="product-basic-item-label">Annual Sales Revenue: </div>
                <div class="product-basic-item-value cursor-pointer ">{{ company.company_annual_revenue }}</div>
              </div>
            </div>
          </div>
          <div class="product-line"></div>
          <div class="product-basic">
            <div class="product-basic-part">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Country:</div>
                <div class="product-basic-item-value">

                </div>
              </div>
            </div>

            <div class="product-basic-part product-basic-part-bottom">
              <div class="product-basic-item">
                <div class="product-basic-item-label">Price:</div>
                <div class="product-basic-item-value">12</div>
              </div>
              <div class="product-basic-item">
                <div class="product-basic-item-label">Inventory:</div>
                <div class="product-basic-item-value">33</div>
              </div>
            </div>

          </div>
          <div class="product-line"></div>
          <div id="company-info-1"  class="me-v"></div>
          <div class="product-basic">
            <div class="photo padding-common" v-if="company.level === 'Diamond'">
              <div class="title card-title">
                <div class="name">Media Gallery</div>
                <div class="icon">
                  <div
                    class="item search-button prev2"
                    style="margin-right: 20px;"
                    @click="prev2"
                  >
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="19" cy="19" r="19" fill="#F99D20" />
                      <path
                        d="M14.0507 19.0002L22.064 27.0135L23.9493 25.1282L17.816 18.9948L23.9493 12.8615L22.064 10.9868L14.0507 19.0002Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div class="item search-button next2" @click="next2">
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="19" cy="19" r="19" fill="#F99D20" />
                      <path
                        d="M23.9493 19.0002L15.936 27.0135L14.0507 25.1282L20.184 18.9948L14.0507 12.8615L15.936 10.9868L23.9493 19.0002Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="swiper-container swiper2">
                  <div class="swiper-wrapper">
                    <div v-if="video" class="video-content imgshows swiper-slide">
                      <video-player
                        :options="{
                        poster,
                        controls: true,
                        sources: [
                          {
                            src: video,
                            type: 'video/mp4'
                          }
                        ]
                      }"
                      />
                    </div>
                    <div
                      class="imgshows swiper-slide"
                      v-for="(img, index) in company.information_panel_image"
                      :key="index"
                    >
                      <img alt="" :src="img.url" />
                    </div>
                  </div>
                </div>
                <!--            <div class="swiper-button-prev prev2" @click="prev2"></div>-->
                <!--            <div class="swiper-button-next next2" @click="next2"></div>-->
              </div>
            </div>
          </div>
          <div class="product-line"></div>
          <div id="company-info-3"  class="me-v"></div>
          <div class="product-basic">
            <product
              :id="id"
              :search="searchProductValue"
              :key="productTimer"
            />
          </div>
          <div class="product-line"></div>
          <template v-if="role">
            <div id="company-info-4"  class="me-v"></div>
            <div class="product-basic">
              <companyDocument :id="id"  />
            </div>

            <div class="product-line"></div>
            <div id="company-info-5"  class="me-v"></div>
            <div class="product-basic">
              <factory :id="id"  />
            </div>
            <div class="product-line"></div>
            <div id="company-info-6"  class="me-v"></div>
            <div class="product-basic">
              <member :id="id"  :role="role" />
            </div>

          </template>
          <template v-else>
            <div
              class="not-login"
              v-if="
              ['document', 'factory', 'member'].indexOf(companyDetailType) > -1
            "
            >
              <div class="pic">
                <img src="@/assets/not-login-info.png" />
              </div>
              <div class="info search-button" @click="login()">
                <div class="warning">
                  <img src="@/assets/warning.png" />
                </div>
                Login to see more features
              </div>
            </div>
          </template>





        </div>
        <div class="right">
          <div class="contact">
            <div class="seller">
              <div class="seller-logo">
                23
              </div>
              <div class="seller-info">
                <div class="seller-name">John</div>
                <div class="seller-title">Sale</div>
              </div>
            </div>
            <div class="seller-btn">Contact</div>

          </div>

          <div class="right-line"></div>
          <div class="right-btns">
            <div class="right-btn">
              <div class="right-btn-word">Sample</div>
              <div class="right-btn-icon"></div>
            </div>
            <div class="right-btn" >
              <div class="right-btn-word">Price</div>
              <div class="right-btn-icon"></div>
            </div>
            <div class="right-btn" >
              <div class="right-btn-word">Place Orders</div>
              <div class="right-btn-icon"></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  </gm-skeleton>
</template>

<script>
  import "swiper/swiper-bundle.min.css";
  import Swiper from "swiper";
  import Ads from "@/components/ads";
  import api from "@/api";
  import VideoPlayer from "@/components/VideoPlayer";
  import companyDocument from "./company-info-documents-pc";
  import factory from "./company-info-factory-pc";
  import member from "./company-info-member-pc";
  import product from "./company-info-product-pc";
  import basicProduct from "./company-info-product-basic-pc";
  import { mapState } from "vuex";
  import Askquestion from "@/components/askquestion";
  import poster from "@/assets/poster2.png";
  import PopLogin from "@/components/popLogin";

  const {
    _queryCompanyCompany,
    // _queryCompanyProduct,
    _exchangeBusinessCard,
    _createFavorite,
    _deleteFavorite
  } = api;
  export default {
    computed: {
      ...mapState("user", [
        "role",
        "first_name",
        "last_name",
        "avatar",
        "user_id"
      ])
    },
    // props: ["company"],

    components: {
      Ads,
      VideoPlayer,
      companyDocument,
      factory,
      member,
      product,
      Askquestion,
      basicProduct,
      PopLogin
    },
    data() {
      return {
        poster,
        companyDetailType: "profile",
        id: 0,
        loading: false,
        company: {},
        companyProduct: [],
        video: "",
        swiper1: "",
        swiper2: "",
        swiper3: "",
        searchProductValue: "",
        showExchange: false,
        btnloading: false,
        exchangeBusinessCardLoading: false,
        is_rated: false,
        is_exchanged_business_card: false,
        highLight: [],
        showInfo: false,
        productTimer: "",
        showPopLogin: false,
        selected : 0,
        showSkeleton: true
      };
    },
    created() {
      const id = this.$route.params.id;
      this.id = id;

      this.loading = true;

      _queryCompanyCompany({ id })
        .then(response => {
          const { data } = response;
          // this.role = data.role;
          this.company = data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showSkeleton =false;
          this.loading = false;
          const data = this.company;
          this.is_exchanged_business_card = data.is_exchanged_business_card;
          this.level = data.level;
          this.level_color = data.level_color;
          this.video = data.video?.url;
          this.highLight = data.highlights;
          this.initSwiper2();
        });
    },
    watch: {
      company() {}
    },
    mounted() {
      window.addEventListener("scroll", this.onMidScroll, true);

      if (Object.keys(this.$route.query).length > 0) {
        this.companyDetailType = this.$route.query.type;
        this.searchProductValue = this.$route.query.s;
      }
      this.$nextTick(() => {});
      // const data = this.company;
      // _queryCompanyProduct({ 'id':this.id,limit:30 })
      //   .then(response => {
      //     const { data } = response;
      //     this.companyProduct = data.data;
      //
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //     this.initSwiper1()
      //     this.initSwiper2()
      //     this.initSwiper3()
      //   });
    },
    methods: {
      onMidScroll() {
        let offsetTopArr = [];
        let anchors = document.querySelectorAll(".product-basic");
        anchors.forEach(i => { offsetTopArr.push(i.offsetTop) });
        let content = document.querySelector("html");
        let scrollTop = content.scrollTop;
        var curIndex = 0;
        for (let i = 0; i < offsetTopArr.length; i++) {
          if (scrollTop + 150 >= offsetTopArr[i]) {
            curIndex = i;
          }
        }
        this.selected = curIndex;
      
      },
      choose(num){
        this.selected = num;
        var element = document.getElementById('company-info-'+num);
        element.scrollIntoView({behavior:"smooth",block: "start", inline: "nearest"});
      },
      favorite(company) {
        if (this.role === "") {
          this.$refs.popLogin.showLogin = true;
        } else {
          if (this.company.is_favorite) {
            this.company.is_favorite = false;
            _deleteFavorite({ id: company.id, type: 1 });
          } else {
            this.company.is_favorite = true;
            _createFavorite({ related_id: company.id, type: 1 });
          }
        }
      },
      login() {
        this.$refs.popLogin.showLogin = true;
        return;
      },
      message(companyId, userId) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        // if ("exhibitor" === this.role) {
        //   this.showInfo = true;
        //   return;
        // }
        if (this.is_exchanged_business_card) {
          this.$router.push("/chat?company=" + companyId + "&user=" + userId);
        } else {
          this.showExchange = true;
        }
      },
      viewAllProduct(id) {
        this.$router.push(
          "/company/" +
          id +
          "/products?utm_source=conference.connexima.com&utm_medium=referrals&utm_campaign=connexima_hvb"
        );
      },
      viewProduct(item) {
        if (item.redirect_url) {
          window.open(item.redirect_url);
        } else {
          this.$router.push(`/products/` + item.id);
        }
      },
      cancel() {
        this.showExchange = false;
      },

      showQues() {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        // if ("exhibitor" === this.role) {
        //   this.showInfo = true;
        //   return;
        // }
        if (this.is_exchanged_business_card) {
          this.$refs.askQ.showAskques = true;
        } else {
          this.showExchange = true;
        }
      },

      exchangeBusinessCard(type) {
        if ("" === this.role) {
          this.$refs.popLogin.showLogin = true;
          return;
        }
        // if ("exhibitor" === this.role) {
        //   this.showInfo = true;
        //   return;
        // }
        this.btnloading = true;
        this.exchangeBusinessCardLoading = true;
        _exchangeBusinessCard({
          id: this.id
        })
          .then(() => {
            // console.log(response);
            this.is_exchanged_business_card = true;
            // this.$Notice.open({
            //   title: `You have exchanged business cards with ${this.company_name}.`
            // });

            if (type === "ques") {
              this.showExchange = false;
              // this.showInfo();
              this.$refs.askQ.showAskques = true;
            }
          })
          .catch(({ message }) => {
            this.$Message.error(message);
          })
          .finally(() => {
            this.btnloading = false;
            this.exchangeBusinessCardLoading = false;
          });
      },
      searchProduct() {
        this.productTimer = new Date().getTime();
        this.companyDetailType = "product";
      },
      initSwiper1() {
        this.swiper1 = new Swiper(".swiper1", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 4,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next1",
            prevEl: ".prev1"
          }
        });
      },
      initSwiper3() {
        this.swiper3 = new Swiper(".swiper3", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 6,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next3",
            prevEl: ".prev3"
          }
        });
      },
      initSwiper2() {
        this.swiper2 = new Swiper(".swiper2", {
          autoplay: {
            delay: 3000
          },
          loop: true,
          direction: "horizontal",
          slidesPerView: 3,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".next2",
            prevEl: ".prev2"
          }
        });
      },
      navJump(path) {
        if ("home" == path) {
          this.$router.push("/index");
        }
        if ("products" == path) {
          this.$router.push(
            "/company/" +
            this.id +
            "/products?utm_source=conference.connexima.com&utm_medium=referrals&utm_campaign=connexima_hvb"
          );
        }
      },
      initSwiper() {
        this.swiper = new Swiper(".swiper-container", {
          // autoplay: {
          //   delay: 3000
          // },
          loop: true,
          direction: "horizontal",
          slidesPerView: 4,
          spaceBetween: 10,
          autoplayDisableOnInteraction: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        });
      },
      prev1() {
        this.swiper1.slidePrev();
      },
      next1() {
        this.swiper1.slideNext();
      },
      prev() {},
      next() {},
      prev3() {
        this.swiper3.slidePrev();
      },
      next3() {
        this.swiper3.slideNext();
      },
      prev2() {
        this.swiper2.slidePrev();
      },
      next2() {
        this.swiper2.slideNext();
      },
      changeTitle(title) {
        this.companyDetailType = title;
      }
    },
    filters: {}
  };
</script>
<style lang="less" scoped>
  .homePage{
    margin-top: 100px;
    background: #fff;
    .sc-width{
      margin: 0 auto;
      padding-bottom: 30px;
    }
  }
  .d-flex{
    display: flex;
  }
  .d-flex-space-between{
    justify-content: space-between;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .top-tips{
    height: 44px;
    background: #222C2E;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    color: #fff;
    margin-bottom: 18px;
    .tip-btn{
      background: #f4f4f4;
      border-radius: 5px;
      cursor: pointer;
      color: #222c2e;
      display: flex;
      justify-content: center;
      padding: 2px 10px;
      font-weight: 500;
    }
  }
  .left{
    width: 285px;
    background-image: linear-gradient(130deg,#ffe2df,#faf6e0);
    padding: 30px 20px;
    height: fit-content;
    border-radius: 10px;
    position: sticky;
    top: 85px;
    bottom: 0;
    .logo{
      display: flex;
      justify-content: center;
      img{
        width: 155px;
        height: 155px;
        border-radius: 5px;
      }
      .img{
        width: 155px;
        height: 155px;
        border-radius: 5px;
      }
    }
    .left-part{
      border-radius: 10px;
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 15px;
      .left-part-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 15px;
        cursor: pointer;
        &:hover{
          background: #f4f4f4;
        }
        .left-part-item-word{
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .mid{
    width: 900px;
    .product{
      .product-name{
        font-size: 26px;
        font-weight: 600;
        color: #000;
      }
      .product-icons{

      }
    }
    .product-desc{
      font-size: 15px;
      color: #000;
      font-weight: 400;
      margin: 10px 0 25px 0;
    }
    .product-basic{
      .product-basic-part{
        width: 100%;
        display: flex;
      }
      .product-basic-part-bottom{
        margin: 10px 0;
      }
      .product-basic-item{
        width: 50%;
        display: flex;
        margin-bottom: 5px;
        .product-basic-item-label{
          font-weight: 500;
          font-size: 16px;
          margin-right: 5px;
          color: #000;
        }
        .product-basic-item-value{
          font-size: 15px;
          color: #0d0f12;
          margin-left: 4px;
          padding-top: 2px;
          select{
            border-radius: 5px;
            padding: 3px 6px;
            position: relative;
            bottom: 4px;
          }
        }
        .product-basic-item-value-active{
          color: #FF4835;
          font-weight: 500;
        }
      }
    }
    .product-map{
      width: 100%;
      height: 350px !important;
    }
    .product-line{
      height: 1px;
      background: #e3e3e3;
      margin: 15px auto;
    }
    .faq{
      .faq-title{

        .faq-title-icon{
          margin-right: 10px;
          img{
            width: 28px;
            height: 28px;
          }
        }
        .faq-title-name{
          font-weight: 500;
          font-size: 18px;
          color: #000;
        }
      }
      .faq-content{
        .faq-content-item{
          margin: 10px 0;
          .faq-content-label{
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 3px;
            color: #000;
          }
          .faq-content-value{
            color: #000;
            font-weight: 400;
            font-size: 15px;
          }
        }
      }
    }
  }
  .right{
    width: 300px;
    padding: 30px 30px;
    box-shadow: 0 12px 20px #E3E3E3, 0 4px 8px #E3E3E3, 0 0 16px #E3E3E3;
    margin-top: 20px;
    border-radius: 10px;
    height: fit-content;
    position: sticky;
    top: 95px;
    bottom: 0;
    .contact{
      .seller{
        display: flex;
        .seller-logo{
          margin-right: 10px;
          img{
            width: 65px;
            height: 65px;
            border-radius: 50%;
          }
        }
        .seller-name{
          font-weight: 500;
          font-size: 17px;
        }
      }
      .seller-btn{
        background: #FF4835;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        &:hover{
          opacity: 0.85;
        }
      }

    }
    .right-line{
      height: 1px;
      background: #e3e3e3;
      margin: 13px auto;
    }
    .right-btns{
      .right-btn{
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin-bottom: 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        &:hover{
          background: #f4f4f4;
        }
      }
    }
  }
  .place-order{
    display: flex !important;
    justify-content: space-between !important;
    border: none !important;
    input{
      width: 150px;
      height: 40px;
      border: 1px solid #cccccc;
      border-radius: 10px;
      padding-left: 10px;
      &:focus{
        outline: none;
      }
    }
    .order-submit{
      padding: 5px 12px;
      background: #fff;
      border: 1px solid #cccccc;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .photo {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 28px;
    height: 275px;
    .title {
      padding-top: 25px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .name {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        /* identical to box height, or 140% */

        display: flex;
        align-items: center;

        color: #000000;
      }
      .icon {
        display: flex;
        justify-content: center;
      }
    }
    .imgshows {
      width: 200px;
      height: 150px;
      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .me-v{
    position: relative;
    bottom: 85px;
  }
  .left-part-item-active{
    background: #efeeea;
    /*font-weight: 600;*/
  }
</style>

<style lang="less">

</style>
