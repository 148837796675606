<template>
  <div class="card-content">
    <div class="main">
      <div class="load-data" v-if="loading">
        <div class="pic"><img src="@/assets/loading.gif" /></div>
      </div>
      <div class="content" v-else-if="list.length > 0">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="main-content">
            <div class="title">{{ item.factory_name }}</div>
            <div class="border"></div>
            <div class="category">
              <div class="top">
                <div class="tag">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.89705 18.968C9.36603 18.9696 8.85647 18.7586 8.48205 18.382L0.648054 10.547C0.234643 10.1348 0.0226645 9.56207 0.0680544 8.98001L0.568054 2.41401C0.639353 1.4264 1.42625 0.641631 2.41405 0.573012L8.98005 0.0730117C9.03105 0.0620117 9.08305 0.0620117 9.13405 0.0620117C9.6639 0.063372 10.1718 0.273991 10.5471 0.648012L18.3821 8.48201C18.7573 8.85711 18.9681 9.36594 18.9681 9.89651C18.9681 10.4271 18.7573 10.9359 18.3821 11.311L11.3111 18.382C10.9369 18.7583 10.4277 18.9693 9.89705 18.968ZM9.13305 2.06201L2.56205 2.56201L2.06205 9.13301L9.89705 16.968L16.9671 9.89801L9.13305 2.06201ZM5.65405 7.65402C4.69989 7.65422 3.87847 6.98037 3.69213 6.04458C3.5058 5.10879 4.00646 4.17169 4.88792 3.80639C5.76939 3.44109 6.78615 3.74933 7.31638 4.54259C7.84662 5.33586 7.74265 6.39322 7.06805 7.06801C6.69388 7.44433 6.18473 7.65534 5.65405 7.65402Z"
                      fill="#828282"
                    />
                  </svg>
                </div>
                <div class="main-location">Main Product Category</div>
              </div>
              <div class="type">{{ item.main_product_category }}</div>
            </div>
            <div class="location">
              <div class="top">
                <div class="tag">
                  <svg
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 18.9998C5.73693 17.9224 4.56619 16.7413 3.5 15.4688C1.9 13.5578 8.83662e-07 10.7118 8.83662e-07 7.99981C-0.00141728 5.16735 1.70425 2.6132 4.32107 1.5292C6.93789 0.445211 9.95007 1.04505 11.952 3.04881C13.2685 4.35941 14.0059 6.14219 14 7.99981C14 10.7118 12.1 13.5578 10.5 15.4688C9.43382 16.7413 8.26307 17.9224 7 18.9998ZM7 2.99981C4.23995 3.00311 2.00331 5.23975 2 7.99981C2 9.16581 2.527 11.1848 5.035 14.1858C5.65314 14.9239 6.30902 15.6295 7 16.2998C7.69105 15.6303 8.34724 14.9257 8.966 14.1888C11.473 11.1838 12 9.16481 12 7.99981C11.9967 5.23975 9.76006 3.00311 7 2.99981ZM7 10.9998C5.34315 10.9998 4 9.65666 4 7.99981C4 6.34295 5.34315 4.99981 7 4.99981C8.65686 4.99981 10 6.34295 10 7.99981C10 8.79546 9.68393 9.55852 9.12132 10.1211C8.55871 10.6837 7.79565 10.9998 7 10.9998Z"
                      fill="#828282"
                    />
                  </svg>
                </div>
                <div class="main-location">Location</div>
              </div>
              <div class="address">
                {{ item.city }} {{ item.state }}
                {{ item.country_info ? item.country_info.name : "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-else>
        <div class="no-data">
          <div class="pic"><img src="@/assets/no-data.png" /></div>
          <div class="desc">No Data</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryCompanyFactory } = api;
export default {
  computed: {},
  props: ["id"],

  components: {},
  data() {
    return {
      list: [],
      page: 1,
      limit: 999,
      filter: {},
      total: 0,
      loading: true
    };
  },
  created() {
    const id = this.id;
    _queryCompanyFactory({
      id: id,
      page: this.page,
      limit: this.limit,
      ...this.filter
    })
      .then(response => {
        const { data } = response;
        this.list = data.data;
        this.total = data.total;
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {},
  methods: {
    pageChange(page) {
      this.page = page;
      this.queryFactoryList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryFactoryList();
    },
    handleSearch() {
      this.page = 1;
      this.queryFactoryList();
    },
    queryFactoryList() {
      _queryCompanyFactory({
        id: this.id,
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
.card-content {
  background: #ffffff;
  border-radius: 10px;
  margin: 28px auto;
  .main {
    background: #fff;
    .title {
      display: flex;
      height: 40px;
      .item {
        text-align: center;
        padding-top: 8px;
        font-size: 16px;

        font-weight: 500;
        color: #828282;
        width: 50%;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 5px;
      .item {
        display: flex;
        justify-content: flex-start;
        height: 254px;
        background: #ffffff;
        border-radius: 8px;
        padding: 23px 36px 33px 52px;
        margin-bottom: 28px;
        width: 430px;
        box-shadow: 0 12px 20px #E3E3E3, 0 4px 8px #E3E3E3, 0 0 16px #E3E3E3;
        .main-content {
          width: 408px;
          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height */

            display: flex;
            align-items: center;

            /* Black */

            color: #222222;
          }
          .border {
            height: 0px;
            border: 1px solid #e0e0e0;
          }
          .category {
            margin-top: 29px;
            .top {
              display: flex;
              .tag {
                margin-right: 15px;
              }
            }
            .type {
              margin-top: 9px;
              margin-left: 27px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              display: flex;
              align-items: center;

              /* Gray 1 */

              color: #333333;
            }
          }
          .location {
            margin-top: 28px;
            .top {
              display: flex;
              .tag {
                margin-right: 15px;
              }
            }
            .address {
              margin-top: 9px;
              margin-left: 27px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              /* identical to box height, or 150% */

              display: flex;
              align-items: center;

              /* Gray 1 */

              color: #333333;
            }
          }
        }
      }
      .no-data {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
  .page {
    text-align: CENTER;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
.load-data {
  margin-top: 30px;
  text-align: center;
  .pic {
    img {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
